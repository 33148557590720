
import { Component, Vue } from 'vue-property-decorator'
import { DataList } from '@/types/scheduling'
import { pInteger } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'scheduling'
})
export default class BraceletList extends Vue {
  private tableData: DataList[] =[]
  private dateRange = []
  private info: {projectName: string}= {
    projectName: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private pInteger = pInteger
  created () {
    this.getData()
  }

  validateSum (len: number) {
    return (rule: any, value: number, callback: Function) => {
      if (value && value > len) {
        callback(new Error('不能超过项目总人数'))
      } else {
        return callback()
      }
    }
  }

  showColumn (row: DataList) {
    return !this.$dayjs(row.schedulingDate).isBefore(this.$dayjs(Date.now() - 24 * 60 * 60 * 1000))
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.worker.selectProjectSchedulingByPage, {
      ...this.info,
      startDate: this.dateRange ? this.dateRange[0] || '' : '',
      endDate: this.dateRange ? this.dateRange[1] || '' : '',
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onAdd () {
    this.$router.push({ name: 'scheduleListAdd' })
  }

  // 编辑
  onEdit (row: DataList) {
    this.$router.push({ name: 'scheduleListEdit', params: { id: row.id || '' } })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该排班信息?, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.worker.deleteDataProjectScheduling, {
        id: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.getData()
      })
    })
  }
}
